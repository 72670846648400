import { mapGetters, mapActions, mapMutations } from "vuex";
import { uniqBy } from "lodash"
import axios from "@/axios";
import TabsTools from "@shared/light-ray/pages/entity-details/tabs.vue";
import { vlexTabs } from "@shared/tools/utils/toolsTabs.js"
import tools from "@shared/tools/mixins/tools";
import runAllQueries from "@shared/tools/mixins/runAllQueries";
import SearchForm from "./components/SearchForm.vue";
import SearchResults from "./components/SearchResults.vue";
import { checkPermission } from "@shared/utils/functions";
import { getAutopopulatedFiledValues } from "./utils";
import { VLEX_SCREENS } from "./constants";
import { EventBus } from "@/main.js";

// this.afterSearchResponseReceived({ searchResults: results })

export default {
  name: "vLex",

  mixins: [tools, runAllQueries],

  props: {
    mainEntities: {
      type: Array,
      default : () => ([])
    }
  },

  components: {
    TabsTools,
    SearchForm,
    SearchResults
  },

  data() {
    return {
      tabs: vlexTabs.tabsModel,
      isFiltersPanelShown: false,
      filterPanelKey: 0,
    };
  },

  async created() {
    EventBus.$on("onGetQueriesResultsCount", async () => {
      this.SET_IS_RUN_ALL_QUERIES(false);
      this.resetFilterData();
      this.SET_SELECTED_CARD_STATUS("");
      this.tabs.selected = "all";

      // TODO ned to be fixed on the BE side
      await this.getQueriesResultsCount("vlex-tool");
      await this.getToolsStatus();

      if (!this.query?.query) {
        const query = this.searchedQueries?.find(el => el?._id === this.requestId) ?? {};
        this.SET_QUERY(query);
      }

      await this.fetchCardResults({
        tool_name: "vlex-tool",//this.checkName,
        entity_id: this.entityId
      });

      this.setFilterOptions();
      this.$emit("updateQueryTotal", { checkName: "vlex-tool" });
    });

    EventBus.$on("onRunAllQueries", async (params) => {
      this.SET_IS_RUN_ALL_QUERIES(true);
      this.resetFilterData();
      this.resetPagination();
      this.SET_SELECTED_CARD_STATUS("");
      this.tabs.selected = "all";
      this.setResultsScreen();
      this.TOGGLE_LOADING();

      try {
        const { data } = await axios.post("/service/all/query/results", {
          ...params,
          limit: 1000, // TODO
          offset: 0
        });

        if (data?.data) {
          this.SET_SEARCH_RESULTS(data.data);

          this.SET_PAGINATION({
            ...this.paginationData,
            total_count: data.data.length,
          });

          await this.fetchCardResults({
            tool_name: "vlex-tool",
            entity_id: this.entityId,
          });

          this.setFilterOptions();
        }

        this.$emit("updateQueryTotal", { checkName: "vlex-tool" });
      } catch (e) {
        console.log(e)
      } finally {
        this.TOGGLE_LOADING();
      }
    });
  },

  async mounted() {
    await Promise.all([
      this.getToolsStatus(),
      this.populateFields(),
    ]);

    this.SET_REQUEST_HEADERS({
      "x-user-id": this.userId,
      "x-entity-id": this.entityId,
      "x-tool-name": "vlex-tool",
    });

    this.$emit("updateQueryTotal", { checkName: "vlex-tool" })
  },

  beforeDestroy() {
    EventBus.$off("onGetQueriesResultsCount");
    EventBus.$off("onRunAllQueries");
    this.resetState();
  },

  computed: {
    ...mapGetters({
      getUserDetails: "getUserDetails",
      searchedQueries: "searchedQueries",
      currentScreen: "VLexStore/getCurrentScreen",
      query: "VLexStore/getQuery",
      requestId: "VLexStore/getRequestId",
      searchResults: "VLexStore/getSearchResults",
      paginationData: "VLexStore/getPaginationData",
      cardStatusCount: "VLexStore/getCardStatusCount",
    }),

    selectedScreen() {
      return this.screens[this.currentScreen];
    },

    screens() {
      return {
        [VLEX_SCREENS.Form]: {
          component: SearchForm,
          props: {},
          events: {},
        },
        [VLEX_SCREENS.Results]: {
          component: SearchResults,
          props: {
            isFiltersPanelShown: this.isFiltersPanelShown,
            filterPanelKey: this.filterPanelKey,
          },
          events: {
            toggleFilterPanel: this.toggleFilterPanel,
            resetFilterModel: this.resetFilterModel,
            setFilterOptions: this.setFilterOptions,
          },
        },
      }
    },

    isTabsShown() {
      return !this.isSearchFormScreen && checkPermission("checkOutput.output_card_status_tab");
    },

    isSearchFormScreen() {
      return this.currentScreen === VLEX_SCREENS.Form;
    },

    userId() {
      return this.getUserDetails.id;
    },

    entityId() {
      return this.$route.query.entityId;
    },

    checkName() {
      this.$route.query.check_name
    },

    caseId() {
      return this.$route.query.caseid;
    },

    containerHeight() {
      // const tabsHeight = document.getElementById("vlex-card-status-tabs")?.offsetHeight || 0;
      // return this.isTabsShown ? `height: calc(100% - ${tabsHeight}px)` : `height: 100%`;
      return this.isTabsShown ? `height: calc(100% - 33px)` : `height: 100%`;
    }
  },

  methods: {
    ...mapActions({
      getToolsStatus: "getToolsStatus",
      resetState: "VLexStore/resetState",
      fetchCardResults: "VLexStore/fetchCardResults",
      setResultsScreen: "VLexStore/setResultsScreen",
      resetFilters: "VLexStore/resetFilters",
      resetPagination: "VLexStore/resetPagination",
    }),
    ...mapMutations({
      SET_REQUEST_HEADERS: "VLexStore/SET_REQUEST_HEADERS",
      SET_QUERY: "VLexStore/SET_QUERY",
      SET_FILTERS_OPTIONS: "VLexStore/SET_FILTERS_OPTIONS",
      SET_IS_RUN_ALL_QUERIES: "VLexStore/SET_IS_RUN_ALL_QUERIES",
      SET_SEARCH_RESULTS: "VLexStore/SET_SEARCH_RESULTS",
      TOGGLE_LOADING: "VLexStore/TOGGLE_LOADING",
      SET_PAGINATION: "VLexStore/SET_PAGINATION",
      SET_SELECTED_CARD_STATUS: "VLexStore/SET_SELECTED_CARD_STATUS"
    }),

    async populateFields() {
      const data = await axios.get(`/case/form/${this.caseId}/entity/${this.entityId}`);
      const result = getAutopopulatedFiledValues(data?.data?.sections ?? []);
      console.log("populateFields", result)
    },

    setFilterOptions() {
      this.setJurisdictionsOptions();
      this.setDocketNumberOptions();
    },

    setJurisdictionsOptions() {
      const jurisdictionsFromSearch = this.searchResults
        ?.map((el) => ({
          name: el?.data?.jurisdiction?.name,
          value: el?.data?.jurisdiction?.id
        })) ?? [];

      this.SET_FILTERS_OPTIONS({
        field: "jurisdiction", value: [{ group: "All", categories: uniqBy(jurisdictionsFromSearch, "value") }],
      });
    },

    setDocketNumberOptions() {
      const docketNumbers = this.searchResults
        ?.filter(el => "Docket Number" in el?.data?.meta)
        ?.map((el) => ({
          name: el?.data?.meta?.["Docket Number"],
          value: el?.data?.meta?.["Docket Number"]
        })) ?? [];

      this.SET_FILTERS_OPTIONS({
        field: "docketNumber", value: [{ group: "Docket Number", categories: uniqBy(docketNumbers, "value") }],
      });
    },

    toggleFilterPanel(event) {
      this.isFiltersPanelShown = event;
    },

    resetFilterData() {
      this.resetFilters();
      this.resetFilterModel();
    },

    resetFilterModel() {
      this.isFiltersPanelShown = false;
      this.filterPanelKey = this.filterPanelKey + 1;
    },

    selectCardsStatus(_, id) {
      console.log("selectCardsStatus", id)
      this.tabs.selected = id;
      this.SET_SELECTED_CARD_STATUS(id);
    },
  }
};
