<template>
  <div
    class="relative transition-all p-4 rounded-xl border shadow-md"
    :class="[getBorderColorClass(cardStatus), {
      'bg-blue-50 border-blue-600 hover:border-blue-200': isSelected,
      'bg-disabled pointer-events-none': disabled,
      'hover:border-blue-600 cursor-pointer': checkPermission('checkOutput.detailed_card'),
      'hover:bg-blue-50': checkPermission('checkOutput.detailed_card') && !isSelected,
      'border-blue-600 bg-blue-50 hover:bg-blue-100': isSelected,
    }]"
    :style="getHighlightShadowClass(cardStatus)"
    @click="$emit('click')"
  >
    <div class="flex gap-4 justify-between">
      <h3 class="flex items-center gap-4 line-clamp-1 text-ellipsis" v-tippy :title="info.uri">
        <a :href="info.uri" target="_blank" class="mr-2 text-lg font-semibold text-main underline" @click.stop>{{ info.title }}</a>
        <span class="items-center px-2 py-0.5 rounded text-xs font-medium bg-green-50 text-green-700 capitalize">vLex</span>
      </h3>

      <CardDetailsButton
        v-if="checkPermission('checkOutput.comment_icon')"
        v-tippy
        content="card detail"
        :disabled="disabled"
        class="flex-shrink-0"
        @click.stop="showCardDetails($event)"
      />
    </div>

    <p v-if="showMeta" class="my-2 text-sm text-base-content-300">
      <span v-if="'Neutral' in meta">{{ meta.Neutral }}, </span>
      <span v-if="'Citations' in meta">{{ meta.Citations }}, </span>
      <span v-if="'Reported' in meta">{{ meta.Reported }}</span>
    </p>

    <p v-if="showDescription" class="my-2 text-sm">
      <span v-if="'description' in info">{{ info.description }}, </span>
      <span v-if="'snippet' in info" v-html="info.snippet"></span>
    </p>

    <div
      class="mb-2 text-sm"
      :class="{ 'flex gap-4': !selectedCardId, 'block': selectedCardId }"
    >
      <p class="mt-1"><b>Jurisdiction</b> {{ info.jurisdiction.name }}</p>
      <p v-if="'Court' in meta" class="mt-1"><b>Court</b> {{ meta.Court }}</p>
      <p v-if="'Docket Number' in meta" class="mt-1"><b>Docket number</b> {{ meta["Docket Number"] }}</p>
      <p v-if="'Date' in meta" class="mt-1"><b>Date</b> {{ formatCardDate(meta.Date) }}</p>
      <p v-if="'Judgment Date' in meta" class="mt-1"><b>Judgment Date</b> {{ formatCardDate(meta["Judgment Date"]) }}</p>
    </div>

    <CardStatus
      :cardStatus="cardStatus"
      @selectDropdown="selectDropdown"
    />

    <CanvasMenu
      v-if="isCanvasMenuShown"
      :mainEntity="caseEntities"
      :individualData="cardDetails"
      :autorun="autorun"
      :checkId="checkId"
      :queryId="queryId"
      @closeCanvas="isCanvasMenuShown = false"
      @updateCardStatus="updateCardStatus"
  />
  </div>
</template>

<script>
// TODO Sanitize html?
import { mapActions, mapGetters, mapMutations } from "vuex";
import { checkPermission, getShortEntityData } from "@shared/utils/functions";
import CardDetailsButton from "@shared/saas-components/detail-button";
import CanvasMenu from "@shared/saas-components/canvas-menu";
import CardStatus from "@shared/saas-components/card-status/CardStatus.vue";
import {
  getBorderColorClass,
  getHighlightShadowClass,
  resolveCardStatus,
} from "@shared/saas-components/card-status/utils.js";
import { STATUSES } from "@shared/saas-components/card-status/constants.js";
import { formatCardDate } from "../utils";

export default {
  name: "VLexResultCard",

  components: {
    CardStatus,
    CardDetailsButton,
    CanvasMenu
  },

  props: {
    card: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    disabled: { // TODO
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isStatusDropdownShown: false,
      isCanvasMenuShown: false,
      cardDetails: {},
      autorun: {}, //TODO
      isFullView: false,
      isPrinting: false,
      isViewDocument: false,
    }
  },

  computed: {
    ...mapGetters({
      getNeoCaseId: "getNeoCaseId",
      getSelectedEntity: "getSelectedEntity",
      getCaseEntitiesData: "getCaseEntitiesData",
      cardDetailsMap: "VLexStore/getCardDetailsMap",
      selectedCardId: "VLexStore/getSelectedCardId",
      isCardLoading: "VLexStore/getIsCardLoading",
    }),

    meta() {
      return this.card.data?.meta ?? {};
    },
    jurisdiction() {
      return this.card.data?.jurisdiction ?? {};
    },
    showMeta() {
      return "meta" in this.info;
    },
    showDescription() {
      return 'description' in this.info || 'snippet' in this.info;
    },
    info() {
      return this.card.data;
    },

    queryId() {
      return this.card.query_id
    },

    checkId() {
      return this.$route.query.check_id
    },

    entityId() {
      return this.$route.query.entityId;
    },

    // TODO pass as props
    caseEntities() {
      if (!this.getCaseEntitiesData) return [];
      const { case_entities = [] } = this.getCaseEntitiesData;
      return case_entities
        .map((entity) => ({
          ...this.getShortEntityData(entity),
          relations: [],
        }))
        .filter((entity) => entity.entity_type !== "Case");
    },

    cardStatus() {
      return this.cardAPIDetails.status ?? STATUSES.Unresolved;
    },

    cardAPIDetails() {
      return this.cardDetailsMap[this.card.doc_id] ?? {};
    },

    entityType() {
      return this.getSelectedEntity.entity_type === "Person" ? "person" : "company";
    }
  },

  methods: {
    checkPermission,
    getBorderColorClass,
    getHighlightShadowClass,
    formatCardDate,

    ...mapActions({
      updateCard: "VLexStore/updateCard",
      fetchCardResults: "VLexStore/fetchCardResults",
    }),
    ...mapMutations({
      TOGGLE_CARD_LOADING: "VLexStore/TOGGLE_CARD_LOADING",
    }),

    async selectDropdown(event) {
      if (this.isCardLoading) return;

      const key = event?.target?.innerText?.toUpperCase() || event?.toUpperCase();
      if (this.cardAPIDetails.status === key) return;

      const result = await this.updateCard({
        case_id: this.getNeoCaseId,
        card_id: this.card.doc_id,
        entity: this.info.title,
        query_id: this.card.query_id,
        case_check_id: this.$route.query.check_id,
        tool_name: this.$route?.query?.check_name, //|| this.autorun?.headers['x-tool-name'],
        status: resolveCardStatus(key),
        main_entity_id: this.$route.query.entityId,
        card_type: this.cardAPIDetails.card_type ?? this.entityType, // this.getSelectedEntity.entity_type === "Person" ? "person" : "company",
        source_name: "vlex",
      });

      if (result) {
        this.$toast.success("Successfully Updated");
      }
    },

    showCardDetails(e) {
      if (this.isCardLoading) return;
  
      e.stopPropagation();
      if (this.disabled) return;

      this.isCanvasMenuShown = true;

      this.cardDetails = {
        entity_identifier: this.cardAPIDetails.entity_identifier ?? this.entityType, // this.getSelectedEntity.entity_type === "Person" ? "person" : "company",
        case_id: this.getNeoCaseId,
        card_id: this.card.doc_id,
        entity: this.info.title,
        query_id: this.card.query_id,
        tool_name: this.$route?.query?.check_name, // || this.autorun?.headers['x-tool-name'],
        status: resolveCardStatus(this.cardStatus?.toUpperCase()), //this.card.status,
        main_entity_id: this.$route.query.entityId,
        // card_type: this.getSelectedEntity.entity_type === "Person" ? "person" : "company", //this.result.entity_type=='Individual' ? "person": "company",
        card_type:this.cardAPIDetails.card_type ?? this.entityType,
        relations: this.cardAPIDetails.relations ?? [],
        source_name: "vlex",
        notes: this.cardAPIDetails.notes ?? "",
        customer_notes: this.cardAPIDetails.customer_notes ?? "",
        risk_categories: this.cardAPIDetails.risk_categories ?? [],
        bespoke: this.cardAPIDetails.bespoke ?? [],
        entity_status: this.cardAPIDetails.entity_status ?? "",
        screenshot: this.cardAPIDetails.screenshot ?? {},
      };
    },

    toggleDropdown() {
      this.isStatusDropdownShown = !this.isStatusDropdownShown;
    },

    closeDropdown() {
      this.isStatusDropdownShown = false
    },

    async updateCardStatus() {
      this.TOGGLE_CARD_LOADING();
      await this.fetchCardResults({
        tool_name: "vlex-tool",
        entity_id: this.entityId
      });
      this.TOGGLE_CARD_LOADING();
    },

    getShortEntityData(entity) {
      return getShortEntityData(this.getCaseEntitiesData, entity);
    },
  }
}
</script>
