<template>
  <div
    class="w-full"
    :class="{ 'absolute top-0 left-0 h-full z-100': isFullView }"
  >
  <div
    id="printDataSection"
    class="bg-white  border-solid border-gray-200 p-4"
    :class="{
      'h-full pb-24': isFullView || isViewDocument,
      'shadow-md border rounded-md': !isDetailsPrinting
    }"
  >
    <div
      class="flex"
      :class="{
        'justify-between': !isViewDocument,
        'justify-end': isViewDocument
      }"
    >
      <h3 v-show="!isViewDocument" v-tippy :title="info.uri" class="line-clamp-1 text-ellipsis">
        <a :href="info.uri" target="_blank" class="text-lg font-semibold text-main underline">{{ info.title }}</a>
      </h3>

      <div class="flex gap-2">
        <button
          v-if="!isFullView && !isViewDocument"
          type="button"
          class="inline-flex cursor-pointer items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 capitalize mr-2 hover:text-brand-hover"
          id="full-screen-view-btn"
          :disabled="isDocLoading"
          @click="$emit('onFullView')"
        >
          <FullView />
        </button>
        <button
          id="print-button"
          class="inline-flex items-center ml-auto cursor-pointer hover:text-brand-hover"
          :disabled="isDocLoading"
          @click="printDocument"
        >
          <span class="h-full inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 capitalize">
            <Printer />
          </span>
        </button>
        <button
          type="button"
          id="close-button"
          class="ml-auto cursor-pointer hover:text-brand-hover"
          :disabled="isDocLoading"
          @click="closeDetails"
        >
          <Close />
        </button>
      </div>
    </div>

    <div v-show="!isViewDocument">
    <p v-if="showMeta" class="my-4 text-sm text-base-content-300">
      <span v-if="'Neutral' in meta">{{ meta.Neutral }}, </span>
      <span v-if="'Citations' in meta">{{ meta.Citations }}, </span>
      <span v-if="'Reported' in meta">{{ meta.Reported }}</span>
    </p>

    <p v-if="showDescription" class="my-4 text-sm">
      <span v-if="'description' in info">{{ info.description }}, </span>
      <span v-if="'snippet' in info" v-html="info.snippet"></span>
    </p>

    <div class="mb-4">
      <p class="mt-2"><b>Jurisdiction</b> {{ info.jurisdiction?.name }}</p>
      <p v-if="'Court' in meta" class="mt-2"><b>Court</b> {{ meta.Court }}</p>
      <p v-if="'Docket Number' in meta" class="mt-2"><b>Docket number</b> {{ meta["Docket Number"] }}</p>
      <p v-if="'Date' in meta" class="mt-2"><b>Date</b> {{ meta.Date }}</p>
      <p v-if="'Judgment Date' in meta" class="mt-2"><b>Judgment Date</b> {{ meta["Judgment Date"] }}</p>
    </div>

    <div class="flex  items-center gap-2 mt-2">
      <button
        type="button"
        class="text-sm font-medium underline"
        :class="{
          'text-base-content-300': isDocLoading || document?.length > 0,
          'text-main cursor-pointer': !isDocLoading && !document
        }"
        :disabled="isDocLoading"
        @click="viewDocument"
      >
        View Document
      </button>
      <Loader v-if="isDocLoading" :size="4" />
    </div>
    </div>

    <div
      v-show="isViewDocument"
      id="html-container"
      class="max-h-full my-3 py-4 overflow-auto"
    ></div>
  </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Loader from "@shared/loader";
import { FullView, Printer, Close } from "./icons"
import printDataMixin from "@shared/tools/mixins/printDataMixin";

export default {
  name: "VLexCardDetails",

  mixins: [printDataMixin],

  components: {
    Loader,
    FullView,
    Printer,
    Close,
  },

  props: {
    isFullView: {
      type: Boolean,
      default: false,
    },
    isDetailsPrinting: {
      type: Boolean,
      default: false,
    },
    isViewDocument: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapGetters({
      searchResults: "VLexStore/getSearchResults",
      selectedCardId: "VLexStore/getSelectedCardId",
      selectedCard: "VLexStore/getSelectedCard",
      document: "VLexStore/getDocument",
      isDocLoading: "VLexStore/getIsDocLoading",
    }),

    meta() {
      return this.card.data?.meta ?? {};
    },
    jurisdiction() {
      return this.card.data?.jurisdiction ?? {};
    },
    showMeta() {
      return "meta" in this.info;
    },
    showDescription() {
      return 'description' in this.info || 'snippet' in this.info;
    },
    info() {
      return this.card.data ?? {};
    },
    card() {
      return this.selectedCard ?? {};
    }
  },

  methods: {
    ...mapMutations({
      SET_SELECTED_CARD_ID: "VLexStore/SET_SELECTED_CARD_ID",
      TOGGLE_LOADING: "VLexStore/TOGGLE_LOADING",
    }),
    ...mapActions({
      fetchDocument: "VLexStore/fetchDocument",
    }),

    async viewDocument() {
      if (!this.document) {
        await this.fetchDocument();
      }

      this.$emit("onViewDocument", true);

      const container = document.getElementById("html-container");
      container.innerHTML = this.document;
    },

    async printDocument() {
      this.$emit("onDetailsPrinting", true);
      const htmlPDF = await this.printData("printDataSection", this.info.title);
      this.$emit("onDetailsPrinting", false);
      return htmlPDF;
    },
  
    closeDetails() {
      this.$emit("onCloseDetails");
    }
  }
}
</script>
