<template>
  <div class="w-full">
  <p class="flex items-center gap-4 mb-4 font-semibold">
    <span>vLex</span>
    <Loader v-if="isLoading" :size="4" />
  </p>
    <div class="flex flex-col flex-1">
      <label class="text-gray-500">
        Search
        <span class="text-red-500">*</span>
      </label>
      <vue-multiselect
        class="h-full"
        style="background: var(--brand-area-color)"
        v-model="model.query.value"
        :options="model.query.options"
        :multiple="false"
        :checkboxes="true"
        placeholder="Search"
        tag-placeholder="Add this as new query string"
        group-values="categories"
        group-label="group"
        :show-labels="true"
        label="value"
        :taggable="true"
        :searchable="true"
        :preserve-search="true"
        :close-on-select="true"
        :clear-on-select="true"
        :disabled="isLoading"
        ref="search-query"
        @search-change="onInput($event)"
        @close="closeDropdown('search-query')"
        @open="closeDropdown('search-query')"
      >
        <template slot="option" slot-scope="props">
          <b-dropdown-divider v-if="props.option.value === 'divider'"></b-dropdown-divider>
            <div v-else-if="props.option.$groupLabel" class="flex self-center">
              <span class="font-extrabold capitalize">{{ props.option.$groupLabel }}</span>
            </div>
            <div v-else class="flex self-center">
              <font-awesome-icon color="#32bea6" icon="check" v-if="props.option.visited" class="w-4 h-4"></font-awesome-icon>
              <span class="self-center ml-4" :class="props.option.visited ? '' : props.search ? '' : 'ml-10'">{{ props.option.value || props.search}}</span>
            </div>
        </template>
     </vue-multiselect>
    </div>

  <div class="mt-4">
    <div class="flex w-full gap-4 mt-4">
    <div class="flex flex-col flex-1 space-y-2 w-1/2">
      <label>Jurisdiction</label>
      <vue-multiselect
        v-model="model.jurisdiction.value"
        v-bind="dropdownConfig"
        checkboxes
        multiple
        :options="model.jurisdiction.options"
        placeholder="Select Jurisdiction"
        :disabled="isLoading"
      />
    </div>

    <div class="flex flex-col flex-1 space-y-2 w-1/2">
      <label>State</label>
      <vue-multiselect
        v-model="model.sub_jurisdiction.value"
        v-bind="dropdownConfig"
        multiple
        :options="model.sub_jurisdiction.options"
        placeholder="Select State"
        :disabled="isLoading || isSubJurisdictionDisabled"
      />
    </div>
    </div>

    <div class="flex w-full gap-4 mt-4">
    <div class="flex flex-col flex-1 space-y-2 w-1/2">
      <label>Content</label>
      <vue-multiselect
        v-model="model.content_type.value"
        v-bind="dropdownConfig"
        multiple
        :options="model.content_type.options"
        placeholder="Select Content"
        :disabled="isLoading"
      />
    </div>
    <div class="flex flex-col flex-1 space-y-2 w-1/2">
      <label>Sort by</label>
      <vue-multiselect
        v-model="model.sort.value"
        v-bind="dropdownConfig"
        :options="model.sort.options"
        placeholder="Sort by"
        :disabled="isLoading"
      />
    </div>
    </div>
  </div>

  <div class="flex justify-end w-full gap-4 mt-4">
    <Button
      text="Reset"
      type="primary"
      :disabled="isLoading"
      outline
      @click="resetForm"
    />
    <Button
      text="Search"
      type="primary"
      :disabled="isSubmitDisabled || isLoading"
      @click="handleSearch"
    />
  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { uniqBy } from "lodash"
import osintAxios from "@shared/light-ray/axios/osint-axios";
import axios from "@/axios";
import VueMultiselect from "@shared/saas-components/vue-multiselect/index";
import Button from "@shared/components/button";
import Loader from "@shared/loader";
import {
  resolveQueryString,
  resolveModel,
} from "../utils";
import { DROPDOWN_CONFIG } from "../constants";
import { EventBus } from "@/main.js";

export default {
  name: "VLexSearchForm",

  components: {
    VueMultiselect,
    Button,
    Loader,
  },

  data() {
    return {
      model: {
        query: { value: {}, options: [] },
        jurisdiction: { value: [], options: [] },
        sub_jurisdiction: { value: [], options: [] },
        content_type: { value: [], options: [] },
        sort: { value: {}, options: [] },
      },
    }
  },

  async mounted() {
    await this.fetchDropdownsOptions();

    this.SET_SEARCH_OPTIONS({
      jurisdiction: this.model.jurisdiction?.options[0]?.categories ?? [],
      sub_jurisdiction: this.model.sub_jurisdiction?.options[0]?.categories ?? [],
      content_type: this.model.content_type?.options[0]?.categories ?? [],
      sort: this.model.sort.options[0].categories ?? [],
    });
  },

  computed: {
    ...mapGetters({
      getUserDetails: "getUserDetails",
      getSelectedEntity: "getSelectedEntity",
      getNeoCaseId: "getNeoCaseId",
      isLoading: "VLexStore/getIsLoading",
      getSearchResults: "VLexStore/getSearchResults",
      requestPayload: "VLexStore/getRequestPayload",
    }),

    dropdownConfig() {
      return DROPDOWN_CONFIG;
    },

    userId() {
      return this.getUserDetails.id;
    },

    entityId() {
      return this.$route.query.entityId;
    },

    isSubJurisdictionDisabled() {
      return !this.isUSSelected || (this.isUSSelected && this.model.jurisdiction.value.length > 1);
    },

    isUSSelected() {
      return this.model.jurisdiction.value.find(el => el.value.toLowerCase() === "us");
    },

    isSubmitDisabled() {
      return !this.model.query.value?.value?.length;
    },
  },

  methods: {
    ...mapActions({
      handleVLexSearch: "VLexStore/handleVLexSearch",
      fetchCardResults: "VLexStore/fetchCardResults",
      resetPagination: "VLexStore/resetPagination",
    }),
    ...mapMutations({
      SET_SELECTED_TOOL_QUERY: "SET_SELECTED_TOOL_QUERY",
      SET_REQUEST_ID: "VLexStore/SET_REQUEST_ID",
      SET_QUERY: "VLexStore/SET_QUERY",
      TOGGLE_LOADING: "VLexStore/TOGGLE_LOADING",
      SET_REQUEST_PAYLOAD: "VLexStore/SET_REQUEST_PAYLOAD",
      SET_SEARCH_OPTIONS: "VLexStore/SET_SEARCH_OPTIONS",
      SET_IS_RUN_ALL_QUERIES: "VLexStore/SET_IS_RUN_ALL_QUERIES",
    }),

    async fetchDropdownsOptions() {
      this.TOGGLE_LOADING();
      await Promise.all([
        this.fetchAllJurisdictions(),
        this.fetchQueryStringOptions(),
        this.fetchAllMetaData()
      ]);
      this.setDefaultQueryStringValue();
      this.setDefaultSortValue();
      this.TOGGLE_LOADING();
    },

    async fetchQueryStringOptions() {
      try {
        const { data } = await axios.get(`service/${this.getNeoCaseId}/tools/profiles`);

        if (data?.data?.data?.length > 0) {
          this.model.query.options = [{
            group: "Entities",
            categories: uniqBy(data?.data?.data ?? [], "value").map(el => ({ ...el, name: el.value })),
          }];
        }
      } catch (e) {
        console.log(e);
      }
    },

    async fetchAllJurisdictions() {
      try {
        const { data } = await osintAxios.get("/api/v1/vlex/juridiction");
        const jurisdictions = data.jurisdictions.map((el) => ({ name: el.name, value: el.id }));
        this.model.jurisdiction.options = [{ group: "All", categories: jurisdictions }];
        const subJurisdictions = data.sub_jurisdcitions.map((el) => ({ name: el.name, value: el.id }));
        this.model.sub_jurisdiction.options = [{ group: "All", categories: subJurisdictions }];
      } catch (e) {
        console.log(e);
      }
    },

    async fetchAllMetaData() {
      try {
        const { data } = await osintAxios.get("/api/v1/vlex/metadata");
        const contentTypes = data.content_types.map((el) => ({ name: el.name, value: el.id }));
        this.model.content_type.options = [{ group: "All", categories: contentTypes }];
        const sortOrders = data.sort_orders.map((el) => ({ name: el.name, value: el.id }));
        this.model.sort.options = [{ group: "Sort By", categories: sortOrders }];
      } catch (e) {
        console.log(e);
      }
    },

    async handleSearch() {
      this.SET_IS_RUN_ALL_QUERIES(false);
      this.SET_SELECTED_TOOL_QUERY(null);
      this.SET_QUERY(null);
      this.SET_REQUEST_ID(null);

      this.resetPagination();

      const { query, jurisdiction, sub_jurisdiction, content_type, sort } = this.model;
      const resolvedQueryString = resolveQueryString(query.value.value);

      this.SET_REQUEST_PAYLOAD({
        query: resolvedQueryString,
        ...(jurisdiction.value.length > 0 ? { jurisdiction: resolveModel(jurisdiction) } : {}),
        ...(sub_jurisdiction.value.length > 0 && !this.isSubJurisdictionDisabled
          ? { sub_jurisdiction: resolveModel(sub_jurisdiction) }
          : {}
        ),
        ...(content_type.value.length > 0 ? { content_type: resolveModel(content_type) } : {}),
        ...(sort.value?.value ? { sort: resolveModel(sort) } : {}),
      });

      await this.saveSelectedQueryString(resolvedQueryString);
      await this.handleVLexSearch();

      EventBus.$emit("onGetQueriesResultsCount");
    },

    async saveSelectedQueryString(queryString) {
      try {
         await axios.post(`service/${this.getNeoCaseId}/tools/profiles`, {
          value: queryString,
          platform: "vLex",
          val_type: this.getSelectedEntity.entity_type === "Person" ? "name" : "company_name",
          visited: true,
          entity_id: this.entityId,
        });
      } catch(err) {
        console.log(err)
      }
    },

    setDefaultQueryStringValue() {
      this.model.query.value = {
        value: this.getSelectedEntity?.name,
        name: this.getSelectedEntity?.name,
      };
    },

    setDefaultSortValue() {
      const options = this.model.sort.options[0]?.categories ?? []
      this.model.sort.value = options.find(el => el?.value === "score") ?? options[0]
    },

    resetForm() {
      for (const key in this.model) {
        if (Array.isArray(this.model[key].value)) this.model[key].value = [];
        else this.model[key].value = { name: "", value: "" };
      }
    },

    onInput(value) {
      this.model.query.value = this.model.query.value = { name: value, value: value };
    },

    closeDropdown(ref) {
      this.$refs[ref].search =  this.model.query.value?.value ?? "";
    },
  }
}
</script>
